//import { Environment } from '@celito.clients/singleton';
import { Environment } from '@celito.clients/singleton';
import { WidgetOptions } from '@okta/okta-signin-widget';

import { environment } from '../../../../../../libs/core/src/config/environment';
//import { Environment } from '../../../../../../libs/core/src/singleton/environment-manager';

Environment.getInstance().setEnvironment(environment);
//const envInstance = Environment.getInstance();

interface OktaAuthConfig {
  issuer: string;
  clientId: string;
  redirectUri: string;
}

const oktaAuthConfig: OktaAuthConfig = {
  issuer: Environment.getInstance().getParam('OKTA_ISSUER') as string,
  clientId: Environment.getInstance().getParam('OKTA_CLIENT_ID') as string,
  redirectUri: Environment.getInstance().getParam(
    'OKTA_REDIRECT_URI'
  ) as string,
};

const oktaSignInConfig: WidgetOptions = {
  baseUrl: Environment.getInstance().getParam('OKTA_ISSUER') as string,
  clientId: Environment.getInstance().getParam('OKTA_CLIENT_ID') as string,
  redirectUri: Environment.getInstance().getParam(
    'OKTA_REDIRECT_URI'
  ) as string,
  useInteractionCodeFlow: true,
  authParams: {
    issuer: Environment.getInstance().getParam('OKTA_ISSUER') as string,
  },
  features: {
    rememberMe: false,
  },
  i18n: {
    en: {
      'errors.E0000004':
        'Login verification was unsuccessful. Please try again.',
    },
  },
  // useClassicEngine: true,
};

export { oktaAuthConfig, oktaSignInConfig };
